import { ReactNode } from 'react';
import { useNavigate } from 'react-router';
import { ChevronLeftIcon, LoaderCircleIcon } from 'lucide-react';
import {
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbPage,
  Breadcrumbs,
  BreadcrumbSeparator,
} from '@/components-shad/ui/breadcrumbs';
import { useHeaderComponent } from '@/components/header/useHeaderComponent';
import { SidebarTrigger } from '@/components-shad/ui/sidebar';
import { Separator } from '@/components-shad/ui/separator';
import { Skeleton } from '@/components-shad/ui/skeleton';
import { UserMenu } from '@/components-shad/header/user-menu/UserMenu';
import { Button } from '@/components-shad/ui/button';
import { RefreshButton } from '@/components-shad/RefreshButton';

export type Breadcrumb = {
  title?: string;
  url: string;
  loading?: boolean;
};

type Props = {
  /** Show whether the Header is loading */
  loading?: boolean;

  /** Show whether the description is loading */
  loadDescription?: boolean;

  /**
   * The breadcrumbs of the page. These should only be the hierarchical parents of this page, as the current
   * page will be inserted automatically.
   */
  breadcrumbs?: Breadcrumb[];

  /** Title of the current page */
  title?: string;

  /** Overrides the title HTML page and the current page breadcrumb. Useful when using editable titles. */
  pageTitle?: string;

  /** Description of the page */
  description?: ReactNode;

  /** Whether to display a back button (will navigate to whatever the previous page is). */
  hasBackButton?: boolean;

  /** Actions that can be performed on the page */
  actions?: ReactNode;

  /** Extra content */
  // extraContent?: ReactNode;

  onRefresh?: () => Promise<void>;

  editable?: boolean;
  editInitially?: boolean;
  // editPlaceholder?: string;
  // editAriaLabel?: string;
  onSave?: (title: string) => void;
};

export function Header({
  loading = false,
  loadDescription = false,
  breadcrumbs,
  title,
  pageTitle,
  description,
  hasBackButton,
  actions,
  onRefresh,
  editable = false,
  editInitially = false,
  onSave,
}: Props) {
  const {
    models: { isFetching },
  } = useHeaderComponent({
    isEditable: editable,
    editInitially,
    documentTitle: pageTitle || title,
    onSave,
    onRefresh,
  });
  const navigate = useNavigate();

  return (
    <header className="-mx-[--page-inset] -mt-[--page-inset] [&>div]:px-[--page-inset]">
      <div className="flex min-h-12 items-center justify-between border-b sm:min-h-16">
        <div className="flex items-center gap-4">
          <SidebarTrigger />
          <Separator orientation="vertical" className="h-4" />
          <Breadcrumbs>
            {breadcrumbs?.map((item) => (
              <BreadcrumbItem key={item.url} isLoading={item.loading}>
                <BreadcrumbLink href={item.url}>{item.title}</BreadcrumbLink>
                <BreadcrumbSeparator />
              </BreadcrumbItem>
            ))}
            <BreadcrumbItem isLoading={loading}>
              <BreadcrumbPage>{pageTitle || title}</BreadcrumbPage>
            </BreadcrumbItem>
          </Breadcrumbs>
          {isFetching && <LoaderCircleIcon className="size-4 animate-spin text-muted-foreground" />}
        </div>

        <UserMenu />
      </div>

      <div className="flex flex-wrap items-center justify-between gap-x-4 gap-y-3 border-b py-4 sm:flex-nowrap sm:py-8">
        <div className="flex items-center gap-4">
          {hasBackButton && (
            <Button
              variant="outline"
              size="sm"
              aria-label="Go back"
              onPress={() => navigate(-1)}
              icon={<ChevronLeftIcon />}
            />
          )}

          <div className="flex flex-col gap-2 sm:gap-2.5">
            <h1 className="text-2xl font-bold sm:text-3xl">
              {loading ? <Skeleton className="h-9 w-72" /> : title}
            </h1>
            {loadDescription && loading ? (
              <Skeleton className="h-6 w-64" />
            ) : (
              description && (
                <p className="text-sm text-muted-foreground sm:text-base">{description}</p>
              )
            )}
          </div>
        </div>

        {(actions || onRefresh) && (
          <div className="flex items-center gap-2">
            {onRefresh && (
              <RefreshButton variant="secondary" aria-label="Refresh data" onPress={onRefresh} />
            )}
            {actions}
          </div>
        )}
      </div>
    </header>
  );
}

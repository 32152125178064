import { Fragment, ReactNode, useId } from 'react';
import { cn } from '@/shared/helpers';
import { Skeleton } from '@/components-shad/ui/skeleton';
import { NA_TEXT } from '@/constants';

type DetailListItem = {
  label: string;
  value: ReactNode;
  isLoading?: boolean;
};

type Props = {
  items: DetailListItem[];
  title?: string;
  isLoading?: boolean;
  className?: string;
};

export function DetailList({ items, title, isLoading, className }: Props) {
  const titleId = useId();
  return (
    <div className="space-y-2">
      {title && (
        <span id={titleId} className="text-sm font-semibold">
          {title}
        </span>
      )}
      <dl
        className={cn('grid max-w-full grid-cols-[30%_1fr] gap-2 text-sm', className)}
        aria-labelledby={title ? titleId : undefined}
      >
        {items.map((item) => (
          <Fragment key={item.label}>
            <dt className="content-center text-muted-foreground">{item.label}</dt>
            <dd className="content-center break-all text-right">
              {isLoading || item.isLoading ? (
                <Skeleton className="h-4 w-full" />
              ) : (
                (item.value ?? <span className="text-muted-foreground">{NA_TEXT}</span>)
              )}
            </dd>
          </Fragment>
        ))}
      </dl>
    </div>
  );
}
